import {
  HEIGHT,
  HORIZONTAL,
  DIRECTION,
  RECT,
  ROUND,
  WITH_BORDER,
  BORDER,
  WIDTH,
  DIAMETER,
  TYPE,
  COLOR,
} from "../components/form/Step";
import { fabric } from "fabric";
import { postScriptToImageFetch } from "../services/apiService";
import opentype from "opentype.js";
import { isMobile } from "../components/utils/utils";

export const classNames = function (...classes) {
  return classes.filter(Boolean).join(" ");
};

export const fileSize = 5; // 5mb
export const canvasHeight = 720;
export const defaultWidth = 60;
export const defaultHeight = 40;
export const pxCmRatio = 0.03937007 * 300 * 10;
export const strokeWidth = 2.5 * pxCmRatio; //25 mm -> 2.5 cm
const strokeWidthBorder = 3; //px render as 100%
export const defaultColorFill = "#000000"; //BLACK U
export const textMargin = 0.2; //cm ref value zoom 1
export const textSize = 20; //px ref value zoom 1

export const defaultRect = (formValues, name = null) => {
  return new fabric.Rect({
    ...{
      name: name ? name : "rect",
      width: parseFloat(getWidthRec(formValues).toFixed(2)),
      height: parseFloat(getHeightRec(formValues).toFixed(2)),
      fill: formValues[COLOR] ? formValues[COLOR] : defaultColorFill,
      hasControls: false,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
      selectable: false,
      hoverCursor: "default",
      absolutePositioned: true,
      excludeFromExport: false,
    },
    ...(formValues[BORDER] === WITH_BORDER
      ? {
          strokeWidth: strokeWidth,
          stroke: "gray",
        }
      : {}),
  });
};

export const defaultCircle = (formValues, name = null) => {
  return new fabric.Circle({
    ...{
      name: name ? name : "circle",
      radius: parseFloat(getRadius(formValues).toFixed(2)),
      fill: formValues[COLOR] ? formValues[COLOR] : defaultColorFill,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      hasBorders: false,
      selectable: false,
      hoverCursor: "default",
      absolutePositioned: true,
      excludeFromExport: false,
    },
  });
};

export const getWidth = (formValues) => {
  let width = isMobile() ? defaultWidth / 2 : defaultWidth;

  if (formValues[DIRECTION] !== undefined) {
    width =
      formValues[DIRECTION] === HORIZONTAL
        ? isMobile()
          ? defaultWidth / 2
          : defaultWidth
        : isMobile()
        ? defaultHeight / 2
        : defaultHeight;
  }

  return width;
};

export const getHeight = (formValues) => {
  let height = isMobile() ? defaultHeight / 2 : defaultHeight;

  if (formValues[DIRECTION] !== undefined) {
    height =
      formValues[DIRECTION] === HORIZONTAL
        ? isMobile()
          ? defaultHeight / 2
          : defaultHeight
        : isMobile()
        ? defaultWidth / 2
        : defaultWidth;
  }

  return height;
};

export const setSelection = (obj) => {
  obj.set({
    transparentCorners: false,
    rotatingPointOffset: 40,
    cornerColor: "#58C05F",
    cornerStrokeColor: "#58C05F",
    borderColor: "#58C05F",
    cornerSize: 12,
    padding: 1,
    cornerStyle: "circle",
    borderDashArray: [5, 5],
    borderScaleFactor: 3,
  });
  obj.setControlsVisibility({ mt: false, mb: false, ml: false, mr: false });
  return obj;
};

export const zoomRec = (canvas, formValues, border) => {
  let heightZoom =
    (getHeight(formValues) * 10) /
    ((formValues[HEIGHT]
      ? getHeightRec(formValues) / pxCmRatio
      : getHeight(formValues)) *
      pxCmRatio +
      (border === WITH_BORDER ? strokeWidth : 0));
  let widthZoom =
    (getWidth(formValues) * 10) /
    ((formValues[WIDTH]
      ? getWidthRec(formValues) / pxCmRatio
      : getWidth(formValues)) *
      pxCmRatio +
      (border === WITH_BORDER ? strokeWidth : 0));
  let zoom = Math.min(heightZoom, widthZoom);
  canvas.zoomToPoint(
    new fabric.Point(
      parseInt(canvas.width, 10) / 2,
      parseInt(canvas.height, 10) / 2
    ),
    zoom < 1 ? zoom : 1
  );
};

export const zoomCircle = (canvas, formValues, radius) => {
  let heightZoom =
    (getHeight(formValues) * 10) /
    ((parseInt(radius, 10) ? parseInt(radius, 10) : getHeight(formValues) / 2) *
      pxCmRatio *
      2);
  canvas.zoomToPoint(
    new fabric.Point(
      parseInt(canvas.width, 10) / 2,
      parseInt(canvas.height, 10) / 2
    ),
    heightZoom < 1 ? heightZoom : 1
  );
};

export const resetZoom = (canvas) => {
  canvas.zoomToPoint(
    new fabric.Point(
      parseInt(canvas.width, 10) / 2,
      parseInt(canvas.height, 10) / 2
    ),
    1
  );
};

export const changeCanvasOrientation = (canvas, formValues) => {
  canvas.setWidth(getWidth(formValues));
  canvas.setHeight(getHeight(formValues));
};

export const addRect = (canvas, formValues) => {
  let rect = defaultRect(formValues, null);
  canvas.insertAt(rect, 0, true);
  canvas.centerObject(rect);

  return rect;
};

export const addCircle = (canvas, formValues) => {
  let circle = defaultCircle(formValues, null);
  canvas.insertAt(circle, 0, true);
  canvas.centerObject(circle);

  return circle;
};

export const addTextWidthAndHeight = (canvas, object) => {
  let objectsFind = canvas
    .getObjects()
    .filter((obj) => obj.name === "widthText" || obj.name === "heightText");

  if (objectsFind.length > 0) {
    objectsFind.forEach((objectFind) => {
      canvas.remove(objectFind);
    });
  }

  let widthText = new fabric.Text(`${getWidthPxToMm(object.width)} cm`, {
    name: "widthText",
    fill: "gray",
    hasControls: false,
    hasBorders: false,
    lockMovementX: true,
    lockMovementY: true,
    selectable: false,
    hoverCursor: "default",
    excludeFromExport: true,
    textAlign: "center",
  });

  let heightText = new fabric.Text(`${getHeightPxToMm(object.height)} cm`, {
    name: "heightText",
    fill: "gray",
    hasControls: false,
    hasBorders: false,
    lockMovementX: true,
    lockMovementY: true,
    selectable: false,
    hoverCursor: "default",
    excludeFromExport: true,
    angle: 270,
    textAlign: "center",
  });

  let fontsize = textSize / canvas.getZoom();
  let canvasZoom = canvas.getZoom();

  widthText.set({
    left: object.left + object.width / 2 - widthText.width / canvasZoom / 2 / 2,
    top: object.top - (textMargin * pxCmRatio) / canvasZoom,
    fontSize: fontsize,
  });

  heightText.set({
    top: object.top + object.height / 2 + heightText.width / canvasZoom / 2 / 2,
    left: object.left - (textMargin * pxCmRatio) / canvasZoom,
    fontSize: fontsize,
  });

  canvas.add(widthText);
  canvas.add(heightText);
  canvas.renderAll();
};

export const addImage = async (
  image,
  canvas,
  formValues,
  setLoading,
  storeCode,
  setShowNotification
) => {
  if (image && /^(image)*\/(?:jpe?g|gif|png)/i.test(image.type)) {
    fabricImageFromBlob(image, canvas, formValues, setLoading);
  }
  if (image && /^(image)*\/(svg)/i.test(image.type)) {
    addSvg(URL.createObjectURL(image), canvas, formValues, setLoading);
  }
  if (
    image &&
    (/^(application)*\/(postscript)/i.test(image.type) ||
      /^(application)*\/(pdf)/i.test(image.type))
  ) {
    await convertToSvg(
      image,
      canvas,
      formValues,
      setLoading,
      storeCode,
      setShowNotification
    );
  }
};

async function convertToSvg(
  image,
  canvas,
  formValues,
  setLoading,
  storeCode,
  setShowNotification
) {
  let blob = await postScriptToImageFetch(storeCode, image);
  if (blob instanceof Blob) {
    addSvg(
      URL.createObjectURL(new Blob([blob], { type: "image/svg+xml" })),
      canvas,
      formValues,
      setLoading
    );
  } else {
    setLoading({
      message: "processing…",
      state: false,
    });
    setShowNotification("wrong_convert");
  }
}

const getClipPath = (canvas, formValues) => {
  let clipPath = computeClipPath(canvas, formValues); //before final first calc
  if (formValues[TYPE] === ROUND) {
    addCircle(canvas, formValues);
  } else {
    addRect(canvas, formValues);
  }

  return clipPath;
};

export const addBorder = (canvas, value) => {
  let currentBorderStroke = strokeWidthBorder / canvas.getZoom();
  let border = new fabric.Rect({
    width: value.width * value.scaleX + currentBorderStroke,
    height: value.height * value.scaleY + currentBorderStroke,
    hasControls: false,
    hasBorders: false,
    lockMovementX: true,
    lockMovementY: true,
    selectable: false,
    hoverCursor: "default",
    stroke: "#6EC9FA",
    strokeWidth: currentBorderStroke,
    fill: "rgba(0,0,0,0)",
    top: value.top - currentBorderStroke,
    left: value.left - currentBorderStroke,
    angle: value.angle,
    rotatingPointOffset: value.rotatingPointOffset,
    perPixelTargetFind: true,
    excludeFromExport: true,
  });
  canvas.add(border);
};

export const removeAllBorder = (canvas) => {
  canvas.getObjects().forEach((value) => {
    if (
      value.excludeFromExport &&
      value.name !== "widthText" &&
      value.name !== "heightText"
    ) {
      canvas.remove(value);
    }
  });
};

export const clipPath = (carpet) => {
  let _strokeWidth = carpet.strokeWidth ? carpet.strokeWidth : 0;
  if (carpet.radius) {
    carpet.radius = carpet.radius - _strokeWidth / 2;
  } else {
    carpet.width = carpet.width - _strokeWidth;
    carpet.height = carpet.height - _strokeWidth;
    carpet.excludeFromExport = true;
  }
  carpet.strokeWidth = null;
  return carpet;
};

export const computeClipPath = (canvas, formValues) => {
  let clipath = clipPath(
    formValues[TYPE] === RECT
      ? defaultRect(formValues)
      : defaultCircle(formValues)
  );
  canvas.insertAt(clipath, 0, true);
  canvas.centerObject(clipath);

  return clipath;
};

export const updateImageAndTextClipPath = (canvas, clipPath) => {
  let objects = [];
  //update image and text clipPath and position if exists
  canvas.getObjects().forEach((value) => {
    if (
      value.type === "image" ||
      value.type === "CustomText" ||
      value.type === "group"
    ) {
      objects.push(value);
      canvas.remove(value);
    }
  });
  objects.forEach((object, index) => {
    object.clipPath = clipPath;
    canvas.add(object);
    if (index === objects.length - 1) {
      canvas.setActiveObject(object);
    }
  });
};

export const addText = async (canvas, text, formValues) => {
  const defaultFontFamily = "Arial";
  await loadFont(canvas, defaultFontFamily);
  let clipPath = computeClipPath(canvas, formValues); //before final first calc
  if (formValues[TYPE] === ROUND) {
    addCircle(canvas, formValues);
  } else {
    addRect(canvas, formValues);
  }
  text = setSelection(
    new fabric.CustomText(text, {
      fontFamily: "Arial",
      fill: "#DDE4E6",
      editable: false,
      fontSize:
        (formValues[TYPE] === ROUND
          ? getRadius(formValues)
          : Math.min(getWidthRec(formValues), getHeightRec(formValues))) / 4,
      fontsArr: canvas.fontsArr,
    })
  );

  text.set({
    clipPath: clipPath,
  });

  canvas.add(text);
  canvas.centerObject(text).setActiveObject(text);
};

export function centerHObject(eventData, transform) {
  const target = transform.target;
  const canvas = target.canvas;

  canvas.centerObjectH(target);
}

export function centerVObject(eventData, transform) {
  const target = transform.target;
  const canvas = target.canvas;

  canvas.centerObjectV(target);
}

export function deleteObject(eventData, transform) {
  const target = transform.target;
  const canvas = target.canvas;
  canvas.remove(target);
  canvas.requestRenderAll();
}

function bringForwardObject(
  bringForwardIconImg,
  bringForwardDisabledIconImg,
  sendBackwardsIconImg,
  sendBackwardsDisabledIconImg
) {
  return function bringForwardObject(eventData, transform) {
    const target = transform.target;
    const canvas = target.canvas;

    if (canvas.getObjects().indexOf(target) <= canvas.getObjects().length / 2) {
      canvas.bringForward(target);
      changeForwardAndBackwardsControlsIcon(
        canvas,
        target,
        canvas.getObjects().length / 2,
        bringForwardIconImg,
        bringForwardDisabledIconImg,
        sendBackwardsIconImg,
        sendBackwardsDisabledIconImg
      );
    }
  };
}

function sendBackwardsObject(
  bringForwardIconImg,
  bringForwardDisabledIconImg,
  sendBackwardsIconImg,
  sendBackwardsDisabledIconImg
) {
  return function sendBackwardsObject(eventData, transform) {
    const target = transform.target;
    const canvas = target.canvas;

    if (canvas.getObjects().indexOf(target) > 3) {
      canvas.sendBackwards(target);
      changeForwardAndBackwardsControlsIcon(
        canvas,
        target,
        canvas.getObjects().length / 2,
        bringForwardIconImg,
        bringForwardDisabledIconImg,
        sendBackwardsIconImg,
        sendBackwardsDisabledIconImg
      );
    }
  };
}

export const setColor = (canvas, color) => {
  let object = canvas
    .getObjects()
    .filter((obj) => obj.name === "rect" || obj.name === "circle");
  if (object.length) {
    object[0].set({ fill: color });
    canvas.renderAll();
  }
};

export function initDeleteIcon() {
  const deleteIcon =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='color:red' width='24' height='24' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' /%3E%3C/svg%3E";
  const deleteIconImg = document.createElement("img");
  deleteIconImg.src = deleteIcon;
  fabric.Textbox.prototype.controls.deleteControl =
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon(deleteIconImg),
      cornerSize: 24,
    });
}

export function initCenterHIcon() {
  const centerHIcon =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='vertical-align: middle;fill: currentColor;overflow: hidden;' width='24' height='24' viewBox='0 0 1024 1024' %3E%3Cpath d='M213.333333 682.666667l170.666667-170.666667-170.666667-170.666667v128H85.333333v85.333334h128z m597.333334-213.333334V341.333333l-170.666667 170.666667 170.666667 170.666667v-128h128v-85.333334h-88.405334z m-341.333334 298.666667h85.333334v128h-85.333334z m0-213.333333h85.333334v128h-85.333334z m0-213.333334h85.333334v128h-85.333334z m0-213.333333h85.333334v128h-85.333334z' /%3E%3C/svg%3E";
  const centerHIconImg = document.createElement("img");
  centerHIconImg.src = centerHIcon;
  fabric.Textbox.prototype.controls.centerHControl =
    fabric.Object.prototype.controls.centerHControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 70,
      offsetX: 16,
      cursorStyle: "pointer",
      mouseUpHandler: centerHObject,
      render: renderIcon(centerHIconImg),
      cornerSize: 24,
    });
}

export function initCenterVIcon() {
  const centerVIcon =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='vertical-align: middle;fill: currentColor;overflow: hidden;' width='24' height='24' viewBox='0 0 1024 1024'  %3E%3Cpath d='M554.666667 213.333333V85.333333h-85.333334v128H341.333333l170.666667 170.666667 170.666667-170.666667z m0 725.333334v-128h128l-170.666667-170.666667-170.666667 170.666667h128v128zM128 469.333333h128v85.333334H128z m213.333333 0h128v85.333334H341.333333z m213.333334 0h128v85.333334h-128z m213.333333 0h128v85.333334h-128z' /%3E%3C/svg%3E";
  const centerVIconImg = document.createElement("img");
  centerVIconImg.src = centerVIcon;
  fabric.Textbox.prototype.controls.centerVControl =
    fabric.Object.prototype.controls.centerVControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 100,
      offsetX: 16,
      cursorStyle: "pointer",
      mouseUpHandler: centerVObject,
      render: renderIcon(centerVIconImg),
      cornerSize: 24,
    });
}

export function initBackgroundIcon() {
  const backgroundIcon =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='color:white' width='28' height='150' fill='white' viewBox='0 0 28 150' %3E%3Cpath d='M0 0h28v150H0V0z' /%3E%3C/svg%3E";
  const backgroundIconImg = document.createElement("img");
  backgroundIconImg.src = backgroundIcon;
  fabric.Textbox.prototype.controls.backgroundWhite =
    fabric.Object.prototype.controls.backgroundWhite = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      cursorStyle: "pointer",
      render: renderBackgroundIcon(backgroundIconImg),
    });
}

function renderBackgroundIcon(icon) {
  return function renderBackgroundIcon(
    ctx,
    left,
    top,
    styleOverride,
    fabricObject
  ) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(icon, -14, -16, 28, 150);
    ctx.restore();
  };
}
function renderIcon(icon) {
  return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    ctx.restore();
  };
}

export function initBringForwardAndSendBackwardIcon(
  bringForwardIconImg,
  bringForwardDisabledIconImg,
  sendBackwardsIconImg,
  sendBackwardsDisabledIconImg
) {
  fabric.Textbox.prototype.controls.bringForwardControl =
    fabric.Object.prototype.controls.bringForwardControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 12,
      offsetX: 16,
      cursorStyle: "pointer",
      mouseUpHandler: bringForwardObject(
        bringForwardIconImg,
        bringForwardDisabledIconImg,
        sendBackwardsIconImg,
        sendBackwardsDisabledIconImg
      ),
      render: renderIcon(bringForwardDisabledIconImg),
      cornerSize: 24,
    });

  fabric.Textbox.prototype.controls.sendBackwardsControl =
    fabric.Object.prototype.controls.sendBackwardsControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 40,
      offsetX: 16,
      cursorStyle: "pointer",
      mouseUpHandler: sendBackwardsObject(
        bringForwardIconImg,
        bringForwardDisabledIconImg,
        sendBackwardsIconImg,
        sendBackwardsDisabledIconImg
      ),
      render: renderIcon(sendBackwardsDisabledIconImg),
      cornerSize: 24,
    });
}

export function changeForwardAndBackwardsControlsIcon(
  canvas,
  target,
  maxObjectIndex = canvas.getObjects().length / 2,
  bringForwardIconImg,
  bringForwardDisabledIconImg,
  sendBackwardsIconImg,
  sendBackwardsDisabledIconImg
) {
  let backwardsControlRender;
  let forwardControlRender;
  let backwardsControlCursorStyle;
  let forwardControlCursorStyle;

  if (canvas.getObjects().indexOf(target) > 3) {
    backwardsControlRender = renderIcon(sendBackwardsIconImg);
    backwardsControlCursorStyle = "pointer";
  } else {
    backwardsControlRender = renderIcon(sendBackwardsDisabledIconImg);
    backwardsControlCursorStyle = "not-allowed";
  }
  if (canvas.getObjects().indexOf(target) > maxObjectIndex) {
    forwardControlRender = renderIcon(bringForwardDisabledIconImg);
    forwardControlCursorStyle = "not-allowed";
  } else {
    forwardControlRender = renderIcon(bringForwardIconImg);
    forwardControlCursorStyle = "pointer";
  }

  fabric.Textbox.prototype.controls.bringForwardControl.render =
    fabric.Object.prototype.controls.bringForwardControl.render =
      forwardControlRender;
  fabric.Textbox.prototype.controls.bringForwardControl.cursorStyle =
    fabric.Object.prototype.controls.bringForwardControl.cursorStyle =
      forwardControlCursorStyle;
  fabric.Textbox.prototype.controls.sendBackwardsControl.render =
    fabric.Object.prototype.controls.sendBackwardsControl.render =
      backwardsControlRender;
  fabric.Textbox.prototype.controls.sendBackwardsControl.cursorStyle =
    fabric.Object.prototype.controls.sendBackwardsControl.cursorStyle =
      backwardsControlCursorStyle;
}

const getWidthRec = (formValues) => {
  let tmpWidth;

  if (
    formValues[DIRECTION] === HORIZONTAL ||
    formValues[DIRECTION] === undefined
  ) {
    if (
      parseFloat(formValues[WIDTH], 10) &&
      parseFloat(formValues[HEIGHT], 10)
    ) {
      tmpWidth =
        parseFloat(formValues[WIDTH], 10) > parseFloat(formValues[HEIGHT], 10)
          ? parseFloat(formValues[WIDTH], 10)
          : parseFloat(formValues[HEIGHT], 10);
    } else {
      tmpWidth = isMobile() ? defaultWidth / 2 : defaultWidth;
    }
  } else {
    if (
      parseFloat(formValues[WIDTH], 10) &&
      parseFloat(formValues[HEIGHT], 10)
    ) {
      tmpWidth =
        parseFloat(formValues[WIDTH], 10) < parseFloat(formValues[HEIGHT], 10)
          ? parseFloat(formValues[WIDTH], 10)
          : parseFloat(formValues[HEIGHT], 10);
    } else {
      tmpWidth = isMobile() ? defaultHeight / 2 : defaultHeight;
    }
  }

  return tmpWidth * pxCmRatio;
};

const getHeightRec = (formValues) => {
  let tmpHeight;

  if (
    formValues[DIRECTION] === HORIZONTAL ||
    formValues[DIRECTION] === undefined
  ) {
    if (
      parseFloat(formValues[WIDTH], 10) &&
      parseFloat(formValues[HEIGHT], 10)
    ) {
      tmpHeight =
        parseFloat(formValues[HEIGHT], 10) < parseFloat(formValues[WIDTH], 10)
          ? parseFloat(formValues[HEIGHT], 10)
          : parseFloat(formValues[WIDTH], 10);
    } else {
      tmpHeight = isMobile() ? defaultHeight / 2 : defaultHeight;
    }
  } else {
    if (
      parseFloat(formValues[WIDTH], 10) &&
      parseFloat(formValues[HEIGHT], 10)
    ) {
      tmpHeight =
        parseFloat(formValues[HEIGHT], 10) > parseFloat(formValues[WIDTH], 10)
          ? parseFloat(formValues[HEIGHT], 10)
          : parseFloat(formValues[WIDTH], 10);
    } else {
      tmpHeight = isMobile() ? defaultWidth / 2 : defaultWidth;
    }
  }

  return tmpHeight * pxCmRatio;
};

const getRadius = (formValues) => {
  return parseFloat(
    (parseFloat(formValues[DIAMETER], 10)
      ? parseFloat(formValues[DIAMETER], 10) / 2
      : (isMobile() ? defaultHeight / 2 : defaultHeight) / 2) * pxCmRatio,
    10
  );
};

const Base64Prefix = "data:application/pdf;base64,";

function readBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", reject);
    reader.readAsDataURL(blob);
  });
}

const printPDF = async (pdfData, pages) => {
  const pdfjsLib = await import("pdfjs-dist/build/pdf");
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    window.location.origin + "/pdf.worker.js";
  pdfData = pdfData instanceof Blob ? await readBlob(pdfData) : pdfData;
  const data = atob(
    pdfData.startsWith(Base64Prefix)
      ? pdfData.substring(Base64Prefix.length)
      : pdfData
  );
  // Using DocumentInitParameters object to load binary data.
  const loadingTask = pdfjsLib.getDocument({ data });
  return loadingTask.promise.then((pdf) => {
    // const numPages = pdf.numPages;
    const numPages = 1;
    return new Array(numPages).fill(0).map((__, i) => {
      const pageNumber = i + 1;
      if (pages && pages.indexOf(pageNumber) == -1) {
        return;
      }
      return pdf.getPage(pageNumber).then((page) => {
        //  retina scaling
        const viewport = page.getViewport({ scale: window.devicePixelRatio });
        // Prepare canvas using PDF page dimensions
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);
        return renderTask.promise.then(() => canvas);
      });
    });
  });
};

/**
 * Augments canvas by assigning to `onObjectMove` and `onAfterRender`.
 * This kind of sucks because other code using those methods will stop functioning.
 * Need to fix it by replacing callbacks with pub/sub kind of subscription model.
 * (or maybe use existing fabric.util.fire/observe (if it won't be too slow))
 */
export function initCenteringGuidelines(canvas) {
  if (canvas.getObjects()[0] === undefined) {
    return;
  }

  let canvasWidthCenter = canvas.getObjects()[0].getCenterPoint().x;
  let canvasHeightCenter = canvas.getObjects()[0].getCenterPoint().y;
  let canvasWidthCenterMap = {};
  let canvasHeightCenterMap = {};
  let centerLineMargin = 4;
  let centerLineColor = "rgba(255,0,241,0.5)";
  let centerLineWidth = 1;
  let ctx = canvas.getSelectionContext();
  let viewportTransform;

  for (
    let i = canvasWidthCenter - centerLineMargin,
      len = canvasWidthCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasWidthCenterMap[Math.round(i)] = true;
  }
  for (
    let i = canvasHeightCenter - centerLineMargin,
      len = canvasHeightCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasHeightCenterMap[Math.round(i)] = true;
  }

  function showVerticalCenterLine() {
    showCenterLine(
      canvasWidthCenter + 0.5,
      canvas.getObjects()[0].oCoords.tr.y,
      canvasWidthCenter + 0.5,
      canvas.getObjects()[0].oCoords.br.y
    );
  }

  function showHorizontalCenterLine() {
    showCenterLine(
      canvas.getObjects()[0].oCoords.tl.x,
      canvasHeightCenter + 0.5,
      canvas.getObjects()[0].oCoords.tr.x,
      canvasHeightCenter + 0.5
    );
  }

  function showCenterLine(x1, y1, x2, y2) {
    ctx.save();
    ctx.strokeStyle = centerLineColor;
    ctx.lineWidth = centerLineWidth;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.restore();
  }

  let isInVerticalCenter;
  let isInHorizontalCenter;

  canvas.on("mouse:down", function () {
    viewportTransform = canvas.viewportTransform;
  });

  canvas.on("object:moving", function (e) {
    const object = e.target;
    let objectCenter = object.getCenterPoint();
    let transform = canvas._currentTransform;

    if (!transform) return;

    (isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap),
      (isInHorizontalCenter =
        Math.round(objectCenter.y) in canvasHeightCenterMap);

    if (isInHorizontalCenter || isInVerticalCenter) {
      object.setPositionByOrigin(
        new fabric.Point(
          isInVerticalCenter ? canvasWidthCenter : objectCenter.x,
          isInHorizontalCenter ? canvasHeightCenter : objectCenter.y
        ),
        "center",
        "center"
      );
    }
  });

  canvas.on("before:render", function () {
    if (canvas.contextTop) {
      // usefull during generate final page proof during process add to cart
      canvas.clearContext(canvas.contextTop);
    }
  });

  canvas.on("after:render", function () {
    if (isInVerticalCenter) {
      showVerticalCenterLine();
    }
    if (isInHorizontalCenter) {
      showHorizontalCenterLine();
    }
  });

  canvas.on("mouse:up", function () {
    // clear these values, to stop drawing guidelines once mouse is up
    isInVerticalCenter = isInHorizontalCenter = null;
    canvas.renderAll();
  });
}

export const disableControl = (obj) => {
  obj.set({
    hasControls: false,
    hasBorders: false,
    lockMovementX: true,
    lockMovementY: true,
    selectable: false,
    hoverCursor: "default",
    absolutePositioned: true,
    excludeFromExport: false,
  });

  return obj;
};

const fabricImageFromBlob = (blob, canvas, formValues, setLoading = null) => {
  fabric.Image.fromURL(
    URL.createObjectURL(blob),
    (imageFabric) => {
      const clipPath = getClipPath(canvas, formValues);
      imageFabric = setSelection(imageFabric);
      imageFabric.set({
        clipPath: clipPath,
      });
      canvas.add(imageFabric);
      canvas.centerObject(imageFabric).setActiveObject(imageFabric);
      if (setLoading) {
        setLoading({
          message: "processing…",
          state: false,
          type: "image",
        });
      }
    },
    { crossOrigin: "anonymous" }
  );
};

export function getWidthPxToMm(width) {
  return (width / pxCmRatio).toFixed(1);
}

export function getHeightPxToMm(height) {
  return (height / pxCmRatio).toFixed(1);
}

const addSvg = (blobUrl, canvas, formValues, setLoading) => {
  fabric.loadSVGFromURL(blobUrl, (objects, options) => {
    const clipPath = getClipPath(canvas, formValues);
    let svgGroup = new fabric.Group([
      fabric.util.groupSVGElements(objects, options),
    ]);
    svgGroup.set("src", blobUrl);
    svgGroup.scaleToWidth(
      (formValues[TYPE] === ROUND
        ? getRadius(formValues)
        : Math.min(getWidthRec(formValues), getHeightRec(formValues))) / 2
    );
    svgGroup = setSelection(svgGroup);
    svgGroup.set({
      clipPath: clipPath,
    });
    canvas.add(svgGroup);
    canvas.centerObject(svgGroup).setActiveObject(svgGroup);
    setLoading({ message: "processing…", state: false, type: "image" });
  });
};

export const overrideCanvas = () => {
  // create new class fabric inherit textbox
  fabric.CustomText = fabric.util.createClass(fabric.Textbox, {
    type: "CustomText",
    readyToRender: false,
    fontFamily: "Times New Roman",
    fontsArr: [],
    initialize: async function (text, options) {
      this.callSuper("initialize", text, options);
      this.readyToRender = true;
      if (this.canvas) this.canvas.requestRenderAll();
    },
    _renderChar: async function (
      method,
      ctx,
      lineIndex,
      charIndex,
      _char,
      left,
      top
    ) {
      if (!this.fontsArr[this.fontFamily]) return; // fontsArr define in canvas when loadfont function is call

      var font = this.fontsArr[this.fontFamily].obj;
      var path = font.getPath(_char, left, top, this.fontSize);
      path.fill = this.fill;
      path.draw(ctx);

      if (typeof this.pathData == "undefined") {
        // not exist by default added in order to draw char when toSvg function call
        this.pathData = [];
      }
      this.pathData[lineIndex] = {
        left: left,
        top: top,
      };
    },
    _measureChar: function (_char, charStyle, previousChar, prevCharStyle) {
      if (!this.fontsArr[charStyle.fontFamily]) {
        // fontsArr define in canvas when loadfont function is call
        return {};
      }

      // come from https://github.com/Tod314/fabric.CurvesText/blob/master/src/fabric.CurvesText.js
      var fontCache = this.getFontCache(charStyle),
        fontDeclaration = this._getFontDeclaration(charStyle),
        previousFontDeclaration = this._getFontDeclaration(prevCharStyle),
        couple = previousChar + _char,
        stylesAreEqual = fontDeclaration === previousFontDeclaration,
        width,
        coupleWidth,
        previousWidth;

      if (previousChar && fontCache[previousChar]) {
        previousWidth = fontCache[previousChar];
      }
      let kernedWidth;
      if (fontCache[_char]) {
        kernedWidth = width = fontCache[_char];
      }
      if (stylesAreEqual && fontCache[couple]) {
        coupleWidth = fontCache[couple];
        kernedWidth = coupleWidth - previousWidth;
      }
      if (!width || !previousWidth || !coupleWidth) {
        var ctx = this.getMeasuringContext();
        this._setTextStyles(ctx, charStyle, true);
      }

      let font = this.fontsArr[charStyle.fontFamily].obj;
      font.forEachGlyph(
        _char + " ",
        0,
        0,
        charStyle.fontSize,
        {},
        function (glyph, x, y) {
          kernedWidth = width = x;
        }
      );

      fontCache[_char] = width;

      if (!previousWidth && stylesAreEqual && previousChar) {
        font = this.fontsArr[prevCharStyle.fontFamily].obj;
        font.forEachGlyph(
          previousChar + " ",
          0,
          0,
          prevCharStyle.fontSize,
          {},
          function (glyph, x, y) {
            previousWidth = x;
          }
        );
        fontCache[previousChar] = previousWidth;
      }
      if (stylesAreEqual && !coupleWidth) {
        font = this.fontsArr[prevCharStyle.fontFamily].obj;
        coupleWidth = 0;
        font.forEachGlyph(
          couple + " ",
          0,
          0,
          prevCharStyle.fontSize,
          {},
          function (glyph, x, y) {
            coupleWidth = x;
          }
        );

        fontCache[couple] = coupleWidth;
        kernedWidth = coupleWidth - previousWidth;
      }

      return {
        width: width,
        kernedWidth: kernedWidth,
      };
    },
    toSVG: function (reviver) {
      var result = "";

      var font = this.fontsArr[this.fontFamily].obj;
      for (var i = 0; i < this._textLines.length; i++) {
        var pos = {
          left:
            this.left +
            (this.width * this.scaleX) / 2 +
            this.pathData[i].left * this.scaleX,
          top:
            this.top +
            (this.height * this.scaleY) / 2 +
            this.pathData[i].top * this.scaleY,
        };

        var path = font.getPath(
          this._textLines[i],
          pos.left,
          pos.top,
          this.fontSize * this.scaleY
        );
        path.fill = this.fill;
        result += path.toSVG();
      }

      if (this.angle !== 0) {
        result = `<g transform="rotate(${this.angle}, ${this.left}, ${this.top})">${result}</g>`;
      }

      if (this.clipPath) {
        const clipPathId = "CLIPPATH_" + fabric.Object.__uid++;
        result = `
                <g clip-path="url(#${clipPathId})" >
                 <clipPath id="${clipPathId}">
                    ${this.clipPath.toClipPathSVG(reviver)}
                </clipPath>
                ${result}
                </g>`;
      }

      // for debug
      // const outlinetextpath = new fabric.Path(result, {
      //   fill: 'red',
      // });
      //  this.canvas.add(outlinetextpath);

      return result;
    },
  });

  fabric.CustomText.fromObject = function (object, callback) {
    fabric.Object._fromObject("CustomText", object, callback, "text");
  };

  fabric.CustomText.prototype._wordJoiners = /[]/; // line return only key enter not space
};

export const loadFont = async (canvas, fontFamily) => {
  let fontsArr = {};
  if (canvas.fontsArr === undefined || !canvas.fontsArr[fontFamily]) {
    let font = await opentype.load(
      `${window.location.origin}/fonts/${fontFamily.toLowerCase()}.ttf`
    );
    fontsArr[fontFamily] = {
      obj: font,
      name: fontFamily,
    };
  } else {
    fontsArr = canvas.fontsArr;
  }

  if (canvas.fontsArr === undefined) {
    canvas.fontsArr = fontsArr;
  } else {
    canvas.fontsArr = { ...canvas.fontsArr, ...fontsArr };
  }
};
