export default function ArrowDown({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1691 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M667.364 845.682l-667.364-667.364 178.318-178.318 667.364 667.364 667.364-667.364 178.318 178.318-845.682 845.682z"
      ></path>
    </svg>
  );
}
