import Image from "next/image";
import ArrowPrevious from "./Icons/ArrowPrevious";
import Link from "next/link";
import { getUrl } from "../services/apiService";
import useTranslation from "next-translate/useTranslation";
import LocalesDropdown from "./LocalesDropdown";

export default function Layout({ children, storeCode }) {
  const { t } = useTranslation("common");

  return (
    <div className="min-h-screen flex flex-col lg:block">
      <nav className="bg-white-500 flex flex-col mx-auto py-4 lg:py-8 relative">
        <div className="lg:z-10 lg:absolute lg:left-32 lg:top-1/2 lg:-translate-y-1/2 flex flex-col items-center text-xs lg:text-base pb-4 lg:pb-0">
          <a
            href={!storeCode ? "/" : getUrl(storeCode)}
            className="flex gap-4 items-center transition group uppercase text-xs font-semibold"
          >
            <ArrowPrevious className="w-4 h-4 text-secondary" />
            <span className="group-hover:underline group-hover:decoration-secondary group-hover:underline-offset-[3px]">
              {storeCode ? t("back_to_store") : t("back_to_home")}
            </span>
          </a>
        </div>
        <div className="mx-auto">
          <Link href="/" passHref>
            <a>
              <Image
                src="/images/montapisprologo.svg"
                width={270}
                height={41}
                alt="Logo MonTapisPro"
              />
            </a>
          </Link>
        </div>
        {/*TODO uncomment when english shop activated*/}
        {/*<div className="lg:z-10 lg:absolute lg:right-32 lg:top-1/2 lg:-translate-y-1/2 flex flex-col items-center text-xs lg:text-base pb-4 lg:pb-0">*/}
        {/*  <LocalesDropdown />*/}
        {/*</div>*/}
      </nav>
      <main>{children}</main>
      <footer className="relative mt-auto bg-primary w-full py-8 lg:py-16">
        <div className="flex justify-center">
          <Image
            src="/images/montapisprologo-white.svg"
            width={270}
            height={41}
            alt="Logo MonTapisPro Blanc"
          />
          <div className="absolute bottom-2">
            <p className="text-white text-xs">
              © MonTapisPro&nbsp;-&nbsp;
              <a
                href="https://www.isics.fr"
                target="_blank"
                rel="noreferrer"
                className="hover:underline hover:decoration-secondary hover:underline-offset-[3px]"
              >
                {t("isics_creation")}
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
