import React from "react";
import {
  getFieldMinMax,
  getMappedOption,
  getMappedOptionsValues,
  getOption,
  getOptionsValues,
} from "../utils/utils";
import { Select } from "../input/Select";
import { Input } from "../input/Input";
import { FileButton } from "../input/FileButton";
import { ColorRadio } from "../input/ColorRadio";
import { COLOR, HEIGHT, QUALITY_CHECK, SM, STD, WIDTH } from "./Step";
import useTranslation from "next-translate/useTranslation";
import QualityCheck from "../input/QualityCheck";

export const Fields = ({
  index,
  control,
  watch,
  register,
  flattenWidths,
  options,
  field,
  errors,
  mappedOptionValueStdProduct,
  productTypeSelected,
  alternativeStdProducts,
}) => {
  let mappedOption = !!field.properties?.mapped;
  const option = !mappedOption
    ? getOption(options, field)
    : getMappedOption(options, field.properties.mapped);
  const optionValues =
    field?.type === "drop_down" && mappedOptionValueStdProduct
      ? !mappedOption
        ? mappedOptionValueStdProduct[field.name.toLowerCase()]
        : getMappedOptionsValues(
            mappedOptionValueStdProduct,
            alternativeStdProducts,
            field.properties.mapped
          )
      : getOptionsValues(option);
  const { t } = useTranslation("common");
  let validation = {};
  let show = true;
  let widthRules = {};

  if (
    field.properties?.hidden &&
    (!productTypeSelected || productTypeSelected === SM)
  ) {
    show =
      watch(field.properties.hidden.field) !== field.properties.hidden.value;
  }

  if (field.properties?.validation?.required) {
    validation = { required: t("required_field") };
  }

  if (
    productTypeSelected === SM &&
    field.properties?.validation?.min &&
    field.properties?.validation?.max &&
    show
  ) {
    const { max, min } = getFieldMinMax(field, flattenWidths);

    widthRules = {
      ...validation,
      min: {
        value: min,
        message: t("value_between", { min: min, max: max }),
      },
      max: {
        value: max,
        message: t("value_between", { min: min, max: max }),
      },
      pattern: {
        value: /^(0|[1-9]\d*)(\.\d{1})?$/,
        message: t("incorrect_value"),
      },
    };
  }

  return (
    <div key={index} className="mb-8">
      {((option?.type === "drop_down" && !field?.type) ||
        field?.type === "drop_down") &&
        optionValues.length > 0 &&
        show && (
          <Select
            label={option.title}
            name={option.field_constant}
            options={optionValues}
            control={control}
            errors={errors}
            rules={validation}
          />
        )}
      {((option?.type === "field" && !field?.type) ||
        field?.type === "field") &&
        show && (
          <Input
            label={option.title}
            name={option.field_constant}
            register={register}
            errors={errors}
            rules={Object.keys(widthRules).length > 0 ? widthRules : validation}
            type={Object.keys(widthRules).length > 0 ? "number" : "text"}
            step={Object.keys(widthRules).length > 0 ? "0.1" : false}
          />
        )}
      {option?.type === "file" && show && (
        <FileButton
          label={option.title}
          name={option.field_constant}
          control={control}
          errors={errors}
          rules={validation}
          setWarning={setWarning}
          setLoading={setLoading}
        />
      )}
      {option?.type === "radio" &&
        option.field_constant === COLOR &&
        optionValues.length > 0 &&
        show && (
          <ColorRadio
            label={option.title}
            name={option.field_constant}
            options={optionValues}
            control={control}
            errors={errors}
            rules={validation}
          />
        )}
      {option?.type === "checkbox" &&
        option.field_constant === QUALITY_CHECK &&
        show && (
          <QualityCheck
            label={option.title}
            name={option.field_constant}
            options={optionValues}
            properties={field.properties}
            control={control}
          />
        )}
      {mappedOption &&
        field.properties.mapped.map((name) => (
          <Input
            key={name}
            label={name}
            name={name}
            register={register}
            errors={errors}
            hide
          />
        ))}
    </div>
  );
};
