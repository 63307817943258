import React from "react";
import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";
import { CheckIcon, ShieldCheckIcon } from "@heroicons/react/outline";
export default function QualityCheck({ label, name, properties, control }) {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Switch.Group>
            <div className="flex items-center gap-4 p-4 border border-primary-light bg-[#F6F7F9]">
              <Switch
                checked={value}
                onChange={onChange}
                className={`${
                  value ? "bg-primary" : "bg-white"
                } relative flex items-center justify-center p-1 border border-primary-light transition-colors`}
              >
                <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </Switch>
              <Switch.Label className="flex-1 cursor-pointer">
                <div className="flex items-center justify-between gap-4 text-sm font-semibold text-primary mb-2">
                  <div className="flex-1 flex items-center gap-2">
                    <ShieldCheckIcon className="h-4 w-4" aria-hidden="true" />
                    <span>{properties.label || label}</span>
                  </div>
                  {properties.price_modifier && (
                    <div className="flex-initial">
                      {properties.price_modifier}
                    </div>
                  )}
                </div>
                {properties.description && (
                  <p className="text-xs">{properties.description}</p>
                )}
              </Switch.Label>
            </div>
          </Switch.Group>
        )}
      />
    </div>
  );
}
