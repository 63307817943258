import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Select } from "../input/Select";
import { TextArea } from "../input/TextArea";
import { classNames, loadFont } from "../../utils/misc";
import Close from "../Icons/Close";
import Eye from "../Icons/Eye";
import useTranslation from "next-translate/useTranslation";

const fonts = [
  { value: "Arial", label: "Arial" },
  { value: "Comic Sans MS", label: "Comic Sans MS" },
  { value: "Helvetica", label: "Helvetica" },
  { value: "Optima", label: "Optima" },
  { value: "Times New Roman", label: "Times New Roman" },
];

export const Text = ({ canvas, obj, colors, setDisclosureOpen, isMobile }) => {
  const [position, setPosition] = useState(obj.textAlign);
  const { register, control } = useForm({
    defaultValues: { font: obj.fontFamily, color: obj.fill, text: obj.text },
    mode: "onChange",
  });

  const color = useWatch({ control, name: "color" });
  const text = useWatch({ control, name: "text" });
  const font = useWatch({ control, name: "font" });
  const { t } = useTranslation("common");

  useEffect(() => {
    obj.fill = color;
    canvas.renderAll();
  }, [color, canvas, obj]);

  useEffect(() => {
    obj.text = text;
    canvas.fire("text:changed", { target: obj });
    canvas.renderAll();
  }, [text, canvas, obj]);

  useEffect(() => {
    loadFont(canvas, font).then(() => {
      obj.set({
        fontFamily: font,
        fontsArr: canvas.fontsArr,
      });
      canvas.renderAll();
    });
  }, [font, canvas, obj]);

  useEffect(() => {
    obj.set("textAlign", position);
    canvas.renderAll();
  }, [position, canvas, obj]);

  const handleClose = (e) => {
    e.preventDefault();
    canvas.discardActiveObject().renderAll();
  };

  const handlePosition = (e, position) => {
    e.preventDefault();
    setPosition(position);
  };

  return (
    <div className="relative space-y-4 lg:space-y-8 bg-tertiary px-4 lg:px-10 pt-8 lg:pt-6 pb-10">
      <button
        onClick={handleClose}
        className="absolute top-0 right-0 bg-primary text-white p-4 hover:bg-primary-dark transition"
      >
        <Close className="h-2 w-2 lg:h-3 lg:w-3" />
      </button>
      <TextArea name="text" register={register} label={false} />
      <Select
        name="font"
        control={control}
        options={fonts}
        label={false}
        font
      />
      <div className="grid grid-cols-3 gap-6 bg-white border border-primary-light h-10 lg:h-12 2xl:h-16">
        <button
          className={classNames(
            position === "left" ? "bg-primary text-white" : "bg-white",
            "flex justify-center items-center"
          )}
          onClick={(e) => handlePosition(e, "left")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>
        <button
          className={classNames(
            position === "center" ? "bg-primary text-white" : "bg-white",
            "flex justify-center items-center"
          )}
          onClick={(e) => handlePosition(e, "center")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <button
          className={classNames(
            position === "right" ? "bg-primary text-white" : "bg-white",
            "flex justify-center items-center"
          )}
          onClick={(e) => handlePosition(e, "right")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      <Select
        name="color"
        control={control}
        options={colors}
        label={false}
        color={true}
      />
      {isMobile && (
        <button
          className="shadow-xl w-full bg-secondary text-white px-4 h-10 flex items-center justify-center"
          onClick={(e) => {
            e.preventDefault();
            setDisclosureOpen(true);
          }}
        >
          <span className="block truncate uppercase text-xs font-medium flex items-center">
            {t("show_preview")}
          </span>
          <Eye className="w-7 h-7 pl-2" />
        </button>
      )}
    </div>
  );
};
