import React from "react";
import { addText } from "../../utils/misc";
import { useWatch } from "react-hook-form";
import { COLOR } from "../form/Step";
import Text from "../Icons/Text";

export const TextButton = ({
  canvas,
  label,
  formValues,
  control,
  setClipartsIsOpen,
  text,
}) => {
  const color = useWatch({ control, name: COLOR });
  const handleButton = async (e) => {
    e.preventDefault();
    setClipartsIsOpen(false);
    await addText(canvas, text, { ...formValues, [COLOR]: color });
  };

  return (
    <button
      onClick={handleButton}
      className="inline-flex flex-col items-center justify-center w-full border border-primary-light hover:bg-primary hover:text-white transition py-1 px-2 sm:py-2 sm:px-2 lg:py-3 lg:px-2 2xl:py-4 2xl:px-4 text-xs lg:text-sm font-medium"
    >
      <Text />
      <span>{label}</span>
    </button>
  );
};
