import {
  BORDER,
  DIAMETER,
  FINAL_PAGE_PROOF_PATH,
  HEIGHT,
  HEIGHT_WIDTH,
  IMAGE,
  JSON_FIELD,
  QUANTITY,
  RECT,
  ROUND,
  STD,
  TYPE,
  WIDTH,
  WITH_BORDER,
  WITHOUT_BORDER,
} from "../form/Step";
import {
  addItemToCart,
  deleteItemInCart,
  getUrl,
  uploadImage,
} from "../../services/apiService";
import { pxCmRatio } from "../../utils/misc";

const MOBILE_BREAKPOINT = 1024;

export const getOptionsValues = (option, with_id = false) => {
  return option?.values
    ? option.values.map((value) => ({
        value: value.field_option_constant,
        label: value.title,
        ...(with_id ? { option_type_id: value.option_type_id } : {}),
      }))
    : [];
};

export const getMappedOptionsValues = (
  options,
  alternativeStdProducts,
  fieldConstants
) => {
  let computeMappedOptions = [];
  alternativeStdProducts.forEach((product, index) => {
    let computeProductOption = [];
    for (const attribute in product.custom_attributes) {
      let attributeCode = product.custom_attributes[attribute].attribute_code;
      attributeCode = attributeCode === "length" ? "height" : attributeCode;
      const option = options[attributeCode].find(
        (opt) => opt.value === product.custom_attributes[attribute].value
      );
      computeProductOption = {
        ...computeProductOption,
        ...{ [attributeCode]: { option } },
      };
    }
    for (const fieldConstant of fieldConstants) {
      if (computeMappedOptions[index] === undefined) {
        let option = computeProductOption[fieldConstant.toLowerCase()].option;
        computeMappedOptions[index] = {
          label: option.label,
          value: JSON.stringify({ [fieldConstant]: option.value }),
        };
      } else {
        computeMappedOptions[index] = {
          label:
            computeMappedOptions[index].label +
            " x " +
            computeProductOption[fieldConstant.toLowerCase()].option.label +
            " cm - " +
            product.price.toFixed(2).replace(".", ",") +
            " €",
          value: JSON.stringify({
            ...JSON.parse(computeMappedOptions[index].value),
            ...{
              [fieldConstant]:
                computeProductOption[fieldConstant.toLowerCase()].option.value,
            },
          }),
        };
      }
    }
  });

  return computeMappedOptions;
};

export const getOption = (options, field) => {
  return options.find((option) => option.field_constant === field.name);
};

export const getMappedOption = (options, fieldConstants) => {
  let newOption = { type: "drop_down", title: null, field_constant: null };
  for (const fieldConstant of fieldConstants) {
    let option = options.find(
      (option) => option.field_constant === fieldConstant
    );
    newOption.title =
      newOption.title === null
        ? option.title
        : newOption.title + " x " + option.title;
    newOption.field_constant =
      newOption.field_constant === null
        ? fieldConstant
        : newOption.field_constant + "_" + fieldConstant;
  }

  return newOption;
};
export const getConfigurableProductNearby = (
  width,
  widths,
  configurableProducts
) => {
  let closestWidthValue;
  const tmpClosestWidthValue = widths.reduce(function (prev, curr) {
    //get the closest value
    return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
  });

  if (tmpClosestWidthValue < width) {
    //if closest value < and not equal to width => return form above width value
    const indexOfClosestValue = widths.indexOf(tmpClosestWidthValue);
    closestWidthValue = widths[indexOfClosestValue + 1];
  } else {
    closestWidthValue = tmpClosestWidthValue;
  }

  let closestProduct;
  if (closestWidthValue) {
    closestProduct = configurableProducts.find((configurableProduct) =>
      configurableProduct.name.includes(closestWidthValue)
    );

    return closestProduct;
  }
};

export const processAddOrEditToCart = async (
  setLoading,
  setShowNotification,
  canvas,
  storeCode,
  values,
  getCartId,
  options,
  flattenWidths,
  configurableProducts,
  product,
  itemId = null,
  widths,
  lengths,
  productTypeSelected,
  t
) => {
  // create cart or load if exist
  if (!itemId) {
    setLoading({ message: t("cart_creation"), state: true });
  } else {
    setLoading({ message: t("cart_retrieve"), state: true });
  }
  const cartId = await getCartId();
  if (!cartId) {
    setShowNotification(true);
    setLoading({ message: null, state: false });
    return;
  }
  // end create carte or load

  // send image
  setLoading({ message: t("images_send"), state: true });
  const jsonCanvas = canvas.toJSON(["src"]);

  //usefull when screen change after edit
  jsonCanvas.canvasHeight = canvas.getHeight();
  jsonCanvas.canvasWidth = canvas.getWidth();

  let images = [];
  let error = false;
  for (const [index, object] of jsonCanvas.objects.entries()) {
    if (
      (object.type === "image" || object.type === "group") &&
      object.src &&
      object.src.search("blob:") !== -1
    ) {
      let blob = await fetch(object.src).then((r) => r.blob());
      const formData = new FormData();
      const typeSplit = blob.type.split("/");
      const extension =
        typeSplit.length > 1
          ? typeSplit[typeSplit.length - 1] === "svg+xml"
            ? "svg"
            : typeSplit[typeSplit.length - 1]
          : "png";
      formData.append("image", blob, `image.${extension}`);
      let data = await uploadImage(storeCode, cartId, formData);
      if (data?.name) {
        images.push(data.name);
      } else {
        error = true;
        break;
      }
    }
  }

  if (error) {
    setLoading({ message: null, state: false });
    setShowNotification(true);
    return;
  }

  if (images.length > 0) {
    // update image path on canvas object
    let i = 0;
    for (const [index, object] of jsonCanvas.objects.entries()) {
      if (
        (object.type === "image" || object.type === "group") &&
        object.src &&
        object.src.search("blob:") !== -1
      ) {
        object.src = `${getUrl(
          storeCode
        )}/rest/${storeCode}/V1/isics/images/${cartId}/show/${images[i]}`;
        ++i;
      }
    }
  }
  // end send image and update json

  // send final page proof
  let finalPageProofPath = null;
  setLoading({ message: t("bat_send"), state: true });
  let blob = await getFinalPageProofBlob(canvas, images, storeCode, cartId);
  const formData = new FormData();
  formData.append("image", blob, "final_page_proof.svg");
  let data = await uploadImage(storeCode, cartId, formData);
  if (data?.name) {
    finalPageProofPath = `${getUrl(
      storeCode
    )}/rest/${storeCode}/V1/isics/images/${cartId}/show/${data.name}`;
  } else {
    setLoading({ message: null, state: false });
    setShowNotification(true);
    return;
  }
  // end send final page proof

  // add to cart
  if (!itemId) {
    setLoading({ message: t("cart_add"), state: true });
  } else {
    setLoading({ message: t("cart_edit"), state: true });
  }
  const extensionAttributes = processExtensionAttributes(
    //process custom options and configurable product option
    values,
    options,
    jsonCanvas,
    flattenWidths,
    configurableProducts,
    product,
    finalPageProofPath,
    widths,
    lengths,
    productTypeSelected
  );

  let resDel = null;
  if (itemId) {
    resDel = await deleteItemInCart(storeCode, cartId, itemId);
  }

  const res = await addItemToCart(storeCode, cartId, {
    cartItem: {
      sku: product.sku,
      qty: parseInt(values[QUANTITY], 10),
      quote_id: cartId,
      product_option: {
        extension_attributes: extensionAttributes,
      },
    },
  });
  // end add to cart

  // redirection
  if ((!itemId && res.item_id) || (itemId && resDel && res.item_id)) {
    setLoading({ message: t("redirect"), state: true });
    window.location.href = `${getUrl(storeCode)}/checkout/cart`;
  } else {
    setShowNotification(true);
  }
};

// compute extension attributes (custom options and configurable item option)
export const processExtensionAttributes = (
  values,
  options,
  jsonCanvas,
  flattenWidths,
  configurableProducts,
  product,
  finalPageProofPath,
  widths,
  lengths,
  productTypeSelected
) => {
  // process option with value
  const finalJson = { custom_options: [] };
  Object.keys(values).forEach((fieldName) => {
    // clean not mandatory and useless
    if (
      (values[TYPE] === ROUND &&
        (fieldName === WIDTH ||
          fieldName === HEIGHT ||
          fieldName === BORDER)) ||
      (values[TYPE] === RECT && fieldName === DIAMETER) ||
      fieldName === IMAGE ||
      (productTypeSelected === STD &&
        (fieldName === HEIGHT || fieldName === WIDTH)) ||
      fieldName === HEIGHT_WIDTH
    ) {
      return;
    }
    const option = getOption(options, { name: fieldName });
    const opVal = getOptionsValues(option, true);
    let realValue = values[fieldName];
    if (opVal.length) {
      realValue = opVal.find((_v) => _v.value === realValue)["option_type_id"];
    }
    finalJson.custom_options.push({
      option_id: option.option_id,
      option_value: realValue,
    });
  });

  // add json
  finalJson.custom_options.push({
    option_id: getOption(options, { name: JSON_FIELD }).option_id,
    option_value: JSON.stringify(jsonCanvas),
  });

  // add final page proof path
  finalJson.custom_options.push({
    option_id: getOption(options, { name: FINAL_PAGE_PROOF_PATH }).option_id,
    option_value: finalPageProofPath,
  });

  //merge custom options and configurable items options
  return {
    ...finalJson,
    ...computeSuperAttribute(
      productTypeSelected === STD
        ? findStandardProductByWidthAndHeight(
            widths.find((w) => parseInt(w.label, 10) === values[WIDTH]).value,
            lengths.find((h) => parseInt(h.label, 10) === values[HEIGHT]).value,
            configurableProducts
          )
        : getConfigurableProductNearby(
            values[TYPE] === ROUND ? values[DIAMETER] : values[WIDTH],
            flattenWidths,
            configurableProducts
          ),
      product
    ),
  };
};

// compute configurable items options according to configurable product nearby and find configurable items option according it
const computeSuperAttribute = (configurableProduct, product) => {
  let configurableItemsOptions = { configurable_item_options: [] };
  product.extension_attributes.configurable_product_options.forEach(
    (_configurableProduct) => {
      _configurableProduct.label;
      configurableItemsOptions.configurable_item_options.push({
        option_id: _configurableProduct.attribute_id,
        option_value: Object.values(configurableProduct.custom_attributes).find(
          (ca) =>
            ca.attribute_code.toUpperCase() ===
            _configurableProduct.label.toUpperCase()
        )?.value,
      });
    }
  );

  return configurableItemsOptions;
};

export const recomputePrice = (
  height,
  width,
  border,
  widths,
  configurableProducts,
  minPrice,
  type,
  diameter,
  customisationBorderPrice
) => {
  let price = minPrice;
  if (widths && configurableProducts) {
    if (type === RECT) {
      if (width && width !== "") {
        if (height && height !== "" && (!border || border === WITHOUT_BORDER)) {
          price =
            getConfigurableProductNearby(width, widths, configurableProducts)
              ?.price *
            (height / 100);
        } else if (border && border && border === WITH_BORDER && height) {
          price =
            getConfigurableProductNearby(width, widths, configurableProducts)
              ?.price *
              (height / 100) +
            ((width / 100) * 2 + (height / 100) * 2) * customisationBorderPrice;
        } else {
          price = getConfigurableProductNearby(
            width,
            widths,
            configurableProducts
          )?.price;
        }
      }
    } else if (type === ROUND && diameter && diameter !== "") {
      price = getConfigurableProductNearby(
        diameter,
        widths,
        configurableProducts
      )?.price;
    }
  }

  return price;
};

export function retreiveExtensionAttributes(options, itemOptions) {
  const retrievedOption = [];
  itemOptions.forEach((itemOption) => {
    let tempOption = getOptionById(options, Number(itemOption.option_id));
    let tempOptionValue = tempOption.values
      ? retrieveOptionValueById(tempOption, Number(itemOption.option_value))
          .field_option_constant
      : itemOption.option_value;

    retrievedOption.push({
      option_constant: tempOption.field_constant,
      option_value: tempOptionValue,
    });
  });

  return retrievedOption;
}

export const getOptionById = (options, optionId) => {
  return options.find((option) => option.option_id === optionId);
};

export const retrieveOptionValueById = (option, valueId) => {
  return option.values.find(
    (option_value) => option_value.option_type_id === valueId
  );
};

const getFinalPageProofBlob = async (canvas, images, storeCode, cartId) => {
  let startShape = canvas.getObjects()[0];

  // reset zoom
  let transform = canvas.viewportTransform.slice();
  canvas.viewportTransform = [1, 0, 0, 1, 0, 0];

  //remove object not necessary to final page proof
  let strokeWidth = startShape.stroke ? startShape.strokeWidth : 0;
  let objsToRemove = canvas.getObjects().filter((obj) => obj.excludeFromExport);
  objsToRemove.forEach((e) => {
    canvas.remove(e);
  });

  // update path image and svg
  let i = 0;
  for (const object of canvas.getObjects()) {
    if (object.type === "image" || object.type === "group") {
      if (
        object.type === "image" &&
        object._element?.src &&
        object._element?.src.search("blob:") !== -1
      ) {
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve(img);
            // replace element by new picture
            object.setElement(img);
          };
          img.onerror = reject;
          img.src = `${getUrl(
            storeCode
          )}/rest/${storeCode}/V1/isics/images/${cartId}/show/${images[i]}`;
        });
        ++i;
      } else if (images[i]) {
        // not replace uri for svg but increments if is new svg to get correct index for image
        let urls = images[i].split(".");
        if (urls[urls.length - 1] === "svg") {
          ++i;
        }
      }
    }
  }

  // adjust real size and object position to final
  let width = canvas.getWidth();
  let height = canvas.getHeight();
  let newWidth = startShape.width - strokeWidth;
  let newHeight = startShape.height - strokeWidth;
  updateCanvasSizeAndObjectPositionAccordingNewSize(
    newWidth,
    newHeight,
    canvas
  );

  //get final svg
  let svg = canvas.toSVG({
    viewBox: {
      x: 0,
      y: 0,
      width: newWidth,
      height: newHeight,
    },
    height: `${(newHeight / pxCmRatio).toFixed(1)}cm`,
    width: `${(newWidth / pxCmRatio).toFixed(1)}cm`,
  });

  // put initial zoom for view in error case
  canvas.viewportTransform = transform;

  //revert canvas for view in error case
  updateCanvasSizeAndObjectPositionAccordingNewSize(
    width,
    height,
    canvas,
    true
  );

  // create blob
  let blob = new Blob([svg], { type: "image/svg+xml" });

  // add object remove for view in error case
  objsToRemove.forEach((e) => {
    canvas.add(e);
  });

  canvas.renderAll();

  return blob;
};

export const mapOptionValueToConfigurableProducts = (
  options,
  configurableProducts
) => {
  return configurableProducts.map((configurableProduct) =>
    options.find(
      (width) =>
        width.value ===
        configurableProduct.custom_attributes[
          Object.keys(configurableProduct.custom_attributes)[0]
        ].value
    )
  );
};

export const flattenOptions = (mappedOptions) => {
  let widthsFlatten = [];
  let numberWidthsFlatten = [];
  mappedOptions.forEach((width) => {
    widthsFlatten = [...widthsFlatten, ...width.label.split("-")];
  });
  widthsFlatten.forEach((width) => {
    numberWidthsFlatten = [...numberWidthsFlatten, Number(width)];
  });
  numberWidthsFlatten.sort(function (a, b) {
    return a - b;
  });

  return numberWidthsFlatten;
};

export const alternativeProductOptionValues = (
  alternativeProduct,
  lengths,
  widths
) => {
  let options = {};
  alternativeProduct.extension_attributes.configurable_product_options.forEach(
    (value) => {
      if (value.label.toUpperCase() === "WIDTH") {
        options.width = value.values.map((width) => {
          const tmpWidth = widths.find((_w) => _w.value == width.value_index);
          return {
            label: parseInt(tmpWidth.label, 10),
            value: tmpWidth.value,
          };
        });
      }
      if (value.label.toUpperCase() === "LENGTH") {
        options.height = value.values.map((length) => {
          const tmpLengths = lengths.find(
            (_l) => _l.value == length.value_index
          );
          return {
            label: parseInt(tmpLengths.label, 10),
            value: tmpLengths.value,
          };
        });
      }
    }
  );
  return options;
};

export const findRealValueAccordingOptionId = (id, optionValues) => {
  let option = optionValues.find(
    (opt) => parseInt(opt.value, 10) === parseInt(id, 10)
  );
  return option ? parseInt(option.label, 10) : undefined;
};

export const findStandardProductByWidthAndHeight = (
  widthId,
  heightId,
  alternativeProductOptions
) => {
  const product = alternativeProductOptions.find((item) => {
    let customAttributes = Object.values(item.custom_attributes);
    const attributeLength = customAttributes.find(
      (_attf) => _attf.attribute_code.toUpperCase() === "LENGTH"
    );
    const attributeWidth = customAttributes.find(
      (_atc) => _atc.attribute_code.toUpperCase() === "WIDTH"
    );
    return (
      parseInt(attributeLength.value, 10) === parseInt(heightId, 10) &&
      parseInt(attributeWidth.value, 10) === parseInt(widthId, 10)
    );
  });
  return product;
};

export const findLowestProductStandard = (alternativeProductOptions) => {
  const alternativeProductOptionsSorted = alternativeProductOptions.sort(
    function (itemA, itemB) {
      return itemA.price - itemB.price;
    }
  );

  return alternativeProductOptionsSorted[0];
};

const updateCanvasSizeAndObjectPositionAccordingNewSize = (
  width,
  height,
  canvas,
  back = false
) => {
  let oldTopRef = 0;
  let oldLeftRef = 0;
  canvas.getObjects().forEach((obj, index) => {
    canvas.remove(obj);
    if (index === 0) {
      obj.set({
        top: obj.top + height / 2 - obj.ownMatrixCache.value[5],
        left: obj.left + width / 2 - obj.ownMatrixCache.value[4],
      });
      oldTopRef = obj.ownMatrixCache.value[5];
      oldLeftRef = obj.ownMatrixCache.value[4];
    } else {
      if (obj.clipPath) {
        obj.clipPath.set({
          top: !back ? 0 : height / 2 - oldTopRef,
          left: !back ? 0 : width / 2 - oldLeftRef,
        });
      }
      obj.set({
        top: obj.top + height / 2 - oldTopRef,
        left: obj.left + width / 2 - oldLeftRef,
      });
    }
    canvas.add(obj);
  });
};

export const checkSize = (
  type,
  width,
  height,
  diameter,
  step,
  flattenWidths
) => {
  if (step instanceof Array) {
    // in case of first step pass all step to find width, height diameter step
    step = step.find((s) => {
      let fields = s.tabs?.[1].fields;
      let findAllFields = true;
      if (fields) {
        [WIDTH, HEIGHT, DIAMETER].forEach((_field) => {
          findAllFields &= !!fields.find((val) => val.name === _field);
        });
      }
      if (fields && findAllFields) {
        return s;
      }

      return null;
    });
  }
  let valid = true;
  if (step) {
    const widthField = step.tabs?.[1].fields?.find((val) => val.name === WIDTH);
    const heightField = step.tabs?.[1].fields?.find(
      (val) => val.name === HEIGHT
    );
    const diameterField = step.tabs?.[1].fields?.find(
      (val) => val.name === DIAMETER
    );
    if (type === RECT && widthField && heightField && width && height) {
      // rect
      valid &= validField(width, widthField, flattenWidths);
      valid &= validField(height, heightField, flattenWidths);
    }
    if (type === ROUND && diameterField && diameter) {
      // round
      valid &= validField(diameter, diameterField, flattenWidths);
    }
  } else {
    valid = false;
  }
  return valid;
};

export const getFieldMinMax = (field, flattenWidths) => {
  let customMin = field.properties.validation.min !== true;
  let customMax = field.properties.validation.max !== true;

  let max, min;
  if (customMax) {
    max = field.properties.validation.max;
  } else {
    max = Math.max(...flattenWidths);
  }

  if (customMin) {
    min = field.properties.validation.min;
  } else {
    min = Math.min(...flattenWidths);
  }

  return { max, min };
};

const validField = (value, field, flattenWidths) => {
  value = parseInt(value, 10);
  const { max, min } = getFieldMinMax(field, flattenWidths);
  return value >= min && value <= max;
};

export const isMobile = () => window.innerWidth < MOBILE_BREAKPOINT;
