export default function ArrowPrevious({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1243 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M272.238 582.217l344.869 342.455-100.571 99.328-516.535-512 50.249-49.664 466.286-462.336 100.571 99.328-344.869 342.455h971.191v140.434z"
      ></path>
    </svg>
  );
}
