import React, { useEffect, useState } from "react";
import Image from "next/image";
import { addImage } from "../../utils/misc";
import Close from "../Icons/Close";
import { ClipartItemSkeleton } from "./ClipartItemSkeleton";

export const Cliparts = ({
  setClipartsIsOpen,
  canvas,
  formValues,
  setLoading,
}) => {
  const [cliparts, setCliparts] = useState([]);
  const [clipartsLoading, setClipartsLoading] = useState(true);

  useEffect(() => {
    if (cliparts.length === 0) {
      fetch("/api/cliparts")
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setClipartsLoading(false);
            setCliparts(data);
          }
        });
    }
  }, [cliparts, setCliparts]);

  const handleAddClipart = (clipart) => {
    setLoading({ message: "Chargement", state: true });
    fetch(clipart.url)
      .then((res) => res.blob())
      .then((blob) => addImage(blob, canvas, formValues, setLoading));
  };

  const handleClose = (e) => {
    e.preventDefault();
    setClipartsIsOpen(false);
  };

  return (
    <div className="relative bg-tertiary px-10 py-16">
      <button
        onClick={handleClose}
        className="absolute top-0 right-0 bg-primary text-white p-4 hover:bg-primary-dark transition"
      >
        <Close className="h-3 w-3" />
      </button>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 2xl:grid-cols-5 lg:gap-6">
        {clipartsLoading ? (
          <ClipartItemSkeleton />
        ) : (
          cliparts.length > 0 &&
          cliparts.map((clipart, index) => {
            return (
              <div
                key={index}
                className="text-center border border-primary-light p-4 hover:cursor-pointer hover:scale-110 transition"
                onClick={() => handleAddClipart(clipart)}
              >
                <Image
                  src={clipart.url}
                  width={30}
                  height={30}
                  alt={clipart.label}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
