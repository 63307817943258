import { FileButton } from "../input/FileButton";
import React, { useEffect, useState } from "react";
import { usePrevious } from "../utils/UsePrevious";
import { Tab } from "@headlessui/react";
import { getOption } from "../utils/utils";
import { TextButton } from "../input/TextButton";
import { ClipartButton } from "../input/ClipartButton";
import { Text } from "./Text";
import { Cliparts } from "./Cliparts";
import { Fields } from "./Fields";
import { classNames } from "../../utils/misc";
import { useWatch } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";

const EDITABLE = "EDITABLE";
export const TYPE = "TYPE";
export const HEIGHT = "HEIGHT";
export const WIDTH = "WIDTH";

export const HEIGHT_WIDTH = "HEIGHT_WIDTH";
export const DIAMETER = "DIAMETER";
export const DIRECTION = "DIRECTION";
export const BORDER = "BORDER";
export const IMAGE = "IMAGE";
export const TEXT = "TEXT";
export const COLOR = "COLOR";
export const CLIPARTS = "CLIPARTS";
export const JSON_FIELD = "JSON";
export const QUANTITY = "QUANTITY";
export const FINAL_PAGE_PROOF_PATH = "FINAL_PAGE_PROOF";

export const RECT = "RECT";
export const ROUND = "ROUND";
export const HORIZONTAL = "HORIZONTAL";
export const VERTICAL = "VERTICAL";
export const WITH_BORDER = "TRUE";
export const WITHOUT_BORDER = "FALSE";
export const QUALITY_CHECK = "QUALITY_CHECK";
export const WITH_QUALITY_CHECK = "TRUE";
export const WITHOUT_QUALITY_CHECK = "FALSE";
export const STD = "STD";
export const SM = "SM";

export default function Step({
  options,
  step,
  control,
  errors,
  watch,
  flattenWidths,
  register,
  currentStep,
  setWarning,
  canvas,
  formValues,
  textIsSelected,
  clipartsIsOpen,
  setClipartsIsOpen,
  colors,
  mappedOptionValueStdProduct,
  setProductTypeSelected,
  productTypeSelected,
  alternativeStdProducts,
  smProduct,
  stdProduct,
  setLoading,
  setDisclosureOpen,
  isMobile,
}) {
  const [update, setUpdate] = useState(false);

  const { t } = useTranslation("common");

  const previousStep = usePrevious(currentStep);
  const previousTextIsSelected = usePrevious(textIsSelected);
  const type = useWatch({ control, name: TYPE });

  useEffect(() => {
    setUpdate(currentStep !== previousStep);
  }, [currentStep, previousStep, setUpdate]);

  useEffect(() => {
    setUpdate(previousTextIsSelected !== textIsSelected);
  }, [previousTextIsSelected, textIsSelected, setUpdate]);

  return (
    <>
      {currentStep === previousStep ? ( //force re-render field component when step changed
        <>
          {options &&
            step?.fields &&
            step.fields.map((field, index) => (
              <Fields
                index={index}
                errors={errors}
                control={control}
                watch={watch}
                register={register}
                flattenWidths={flattenWidths}
                key={index}
                field={field}
                options={options}
              />
            ))}
          {options && step?.tabs && !step?.size && (
            <>
              <div className={`grid grid-cols-${step.tabs.length} gap-6 pb-2`}>
                {step.tabs.map((field, index) => {
                  const option = getOption(options, field);

                  return (
                    <div key={index}>
                      {option.field_constant === TEXT && (
                        <TextButton
                          label={option.title}
                          canvas={canvas}
                          formValues={formValues}
                          control={control}
                          setClipartsIsOpen={setClipartsIsOpen}
                          text={t("default_text")}
                        />
                      )}
                      {option.field_constant === CLIPARTS && (
                        <ClipartButton
                          label={option.title}
                          setClipartsIsOpen={setClipartsIsOpen}
                          canvas={canvas}
                        />
                      )}
                      {option?.type === "file" && (
                        <FileButton
                          label={option.title}
                          name={option.field_constant}
                          control={control}
                          errors={errors}
                          setWarning={setWarning}
                          setLoading={setLoading}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {previousTextIsSelected === textIsSelected // force re-render
                ? step.tabs.find((field) => field.name === TEXT)?.[EDITABLE] &&
                  textIsSelected && (
                    <Text
                      canvas={canvas}
                      obj={textIsSelected}
                      colors={colors}
                      setDisclosureOpen={setDisclosureOpen}
                      isMobile={isMobile}
                    />
                  )
                : null}
              {clipartsIsOpen && !textIsSelected && (
                <Cliparts
                  setClipartsIsOpen={setClipartsIsOpen}
                  canvas={canvas}
                  formValues={formValues}
                  setLoading={setLoading}
                />
              )}
            </>
          )}
          {options &&
            step?.tabs &&
            step?.size &&
            (type === RECT ? (
              <Tab.Group
                defaultIndex={step.tabs.findIndex(
                  (tab) => tab.name === productTypeSelected
                )}
                onChange={(index) =>
                  setProductTypeSelected(step.tabs[index].name)
                }
              >
                {smProduct && stdProduct && (
                  <Tab.List className="flex bg-primary-light">
                    {step.tabs.map((tab, index) => (
                      <Tab
                        key={index}
                        className={({ selected }) =>
                          classNames(
                            "w-full h-10 lg:h-12 2xl:h-16 text-sm font-medium leading-5 text-white focus:outline-none",
                            selected
                              ? "bg-primary shadow"
                              : "hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {tab.label}
                      </Tab>
                    ))}
                  </Tab.List>
                )}
                <Tab.Panels className="mt-2">
                  {step.tabs.map((tab, index) => (
                    <Tab.Panel key={index}>
                      {tab.fields?.map((field, index) => (
                        <Fields
                          productTypeSelected={productTypeSelected}
                          index={index}
                          errors={errors}
                          control={control}
                          watch={watch}
                          register={register}
                          flattenWidths={flattenWidths}
                          key={index}
                          field={field}
                          options={options}
                          alternativeStdProducts={alternativeStdProducts}
                          mappedOptionValueStdProduct={
                            tab.name === STD
                              ? mappedOptionValueStdProduct
                              : undefined
                          }
                        />
                      ))}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            ) : (
              // no standard product for not type rect
              step.tabs
                .find((tab) => tab.name === SM)
                .fields.map((field, index) => (
                  <Fields
                    productTypeSelected={productTypeSelected}
                    index={index}
                    errors={errors}
                    control={control}
                    watch={watch}
                    register={register}
                    flattenWidths={flattenWidths}
                    key={index}
                    field={field}
                    options={options}
                  />
                ))
            ))}
        </>
      ) : null}
    </>
  );
}
