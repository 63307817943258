import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { Controller } from "react-hook-form";
import { classNames } from "../../utils/misc";
import ArrowDown from "../Icons/ArrowDown";
import useTranslation from "next-translate/useTranslation";

export const Select = ({
  label,
  name,
  control,
  errors,
  options,
  show = true,
  rules,
  color = false,
  disabled = false,
  font = false,
}) => {
  const hasError = false;
  const { t } = useTranslation("common");

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <div
          className={classNames(
            show ? "" : "hidden",
            "flex flex-col items-center self-stretch"
          )}
        >
          {label && (
            <label
              className={classNames(
                disabled ? "opacity-50" : "",
                "mb-3 w-full text-sm"
              )}
            >
              {label} :
            </label>
          )}
          <Listbox value={value} onChange={onChange} disabled={disabled}>
            {({ open }) => (
              <>
                <div className="relative w-full">
                  <Listbox.Button
                    className={classNames(
                      hasError ? "border-secondary" : "border-primary-light",
                      "relative w-full cursor-default border bg-white pl-4 lg:pl-10 h-10 lg:h-12 2xl:h-16 focus:outline-none disabled:opacity-50 "
                    )}
                  >
                    <span
                      className="block truncate text-sm font-medium flex items-center text-primary-dark"
                      style={font ? { fontFamily: value } : {}}
                    >
                      {value !== "" && color && (
                        <div
                          className="w-8 h-8 rounded-lg mr-4"
                          style={{ backgroundColor: value }}
                        />
                      )}
                      {value !== "" &&
                      options.find((val) => val.value === value)
                        ? options.find((val) => val.value === value)["label"]
                        : t("select_placeholder")}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                      <ArrowDown
                        className="w-3.5 text-secondary"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-sm shadow-lg ring-1 ring-primary-light focus:outline-none"
                    >
                      {options.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-primary text-white"
                                : " text-primary",
                              "relative cursor-default select-none flex items-center h-10 lg:h-12 2xl:h-16 pl-4 lg:pl-6"
                            )
                          }
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <div
                                className={classNames(
                                  selected ? "pl-5 font-medium" : "font-normal",
                                  "block truncate flex items-center"
                                )}
                              >
                                {color && (
                                  <div
                                    className="w-8 h-8 mr-4 rounded-lg"
                                    style={{ backgroundColor: option.value }}
                                  />
                                )}
                                <span
                                  style={
                                    font ? { fontFamily: option.value } : {}
                                  }
                                >
                                  {option.label}
                                </span>
                              </div>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors?.[name] && (
            <div className="text-secondary text-xs lg:text-base w-full">
              {errors?.[name]?.message}
            </div>
          )}
        </div>
      )}
    />
  );
};
