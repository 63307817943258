import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import File from "../Icons/File";

export const FileButton = ({
  label,
  name,
  control,
  errors,
  rules,
  setWarning,
  setLoading,
}) => {
  useEffect(() => {
    if (errors?.[name]) {
      setWarning(errors?.[name]?.message);
    }
  }, [errors, setWarning, name]);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <label
          htmlFor={name}
          className="inline-flex flex-col items-center justify-center w-full border border-primary-light hover:bg-primary hover:text-white hover:cursor-pointer transition py-1 px-1 sm:py-2 sm:px-2 lg:py-3 lg:px-2 2xl:py-4 2xl:px-4 text-xs lg:text-sm font-medium"
        >
          <File />
          <span>{label}</span>
          <input
            id={name}
            name={name}
            type="file"
            className="sr-only"
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={(e) => {
              onChange(e.target.files[0]);
              setLoading({ message: "Chargement", state: true });
            }}
          />
        </label>
      )}
    />
  );
};
