import React from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../utils/misc";
import { Controller } from "react-hook-form";

export const ColorRadio = ({
  label,
  name,
  control,
  errors,
  options,
  rules,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => (
          <RadioGroup value={value} onChange={onChange}>
            <RadioGroup.Label className="mb-3 w-full text-sm">
              {label} :
            </RadioGroup.Label>
            <div className="grid grid-cols-75 mt-4 lg:mt-8">
              {options.map((color) => (
                <RadioGroup.Option
                  key={color.value}
                  value={color.value}
                  style={{ "--tw-ring-color": `${color.value}` }}
                  className={({ checked }) =>
                    classNames(
                      checked ? "ring-offset-1" : "",
                      checked ? "ring-2 ring" : "",
                      "p-2 rounded-xl flex flex-col items-center justify-center cursor-pointer focus:outline-none"
                    )
                  }
                >
                  <div
                    style={{ backgroundColor: color.value }}
                    aria-hidden="true"
                    className="h-11 w-11 border border-black border-opacity-10 rounded-xl"
                  />
                  <span className="rounded mt-2 text-xs text-center">
                    {color.label}
                  </span>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      />
      {errors?.[name] && (
        <div className="text-xs lg:text-base mb-4 text-red-600">
          {errors?.[name]?.message}
        </div>
      )}
    </>
  );
};
