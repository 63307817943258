import React from "react";

export const ClipartItemSkeleton = ({}) => {
  return [...Array(10).keys()].map((index) => (
    <div
      key={index}
      className="w-20 h-16 border border bg-gray-300 border-primary-light animate-pulse"
    />
  ));
};
