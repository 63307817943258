import React from "react";

export const TextArea = ({
  label,
  name,
  register,
  errors,
  disabled,
  rules,
}) => {
  return (
    <div className="flex flex-col items-center">
      {label && <label className="mb-3 w-full">{label} :</label>}
      <textarea
        className="block w-full border border-primary-light p-2 text-sm"
        disabled={disabled}
        {...register(name, rules)}
      />
      {errors?.[name] && (
        <div className="text-xs lg:text-base mb-4 text-red-600">
          {errors?.[name]?.message}
        </div>
      )}
    </div>
  );
};
