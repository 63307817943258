
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "../styles/globals.css";
import Layout from "../components/Layout";
import CartProvider from "../context/cartContext";
import { useLayoutEffect } from "react";
import setLanguage from "next-translate/setLanguage";

const __Page_Next_Translate__ = function MyApp({ Component, pageProps }) {
  const { storeCode } = pageProps;

  async function defineLanguageByStoreCode(locale) {
    await setLanguage(locale);
  }

  useLayoutEffect(() => {
    if (storeCode) {
      defineLanguageByStoreCode(storeCode.split("_").pop());
    }
  }, [storeCode]);

  return (
    <Layout storeCode={storeCode}>
      <CartProvider storeCode={storeCode}>
        <Component {...pageProps} />
      </CartProvider>
    </Layout>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  