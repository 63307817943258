export default function Close({ className }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      className={className}
    >
      <path
        fill="currentColor"
        d="M1024 103.938l-408.023 408.023 408.023 408.1-103.938 103.938-408.1-408.1-408.023 408.1-103.938-103.938 408.023-408.1-408.023-408.023 103.938-103.938 408.023 407.947 408.1-407.947z"
      ></path>
    </svg>
  );
}
