import React, { createContext } from "react";
import { fetchCartId } from "../services/apiService";

export const cartContext = createContext({});

const CartProvider = ({ children, storeCode }) => {
  const getCartId = async () => {
    const cartId = await fetchCartId(storeCode);
    return cartId?.id;
  };

  return (
    <cartContext.Provider value={{ getCartId }}>
      {children}
    </cartContext.Provider>
  );
};

export default CartProvider;
