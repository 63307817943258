import React from "react";
import Clipart from "../Icons/Clipart";

export const ClipartButton = ({ label, setClipartsIsOpen, canvas }) => {
  const handleButton = (e) => {
    e.preventDefault();
    setClipartsIsOpen(true);
    canvas.discardActiveObject().renderAll();
  };

  return (
    <button
      onClick={handleButton}
      className="inline-flex flex-col items-center justify-center w-full border border-primary-light hover:bg-primary hover:text-white transition py-1 px-2 sm:py-2 sm:px-2 lg:py-3 lg:px-2 2xl:py-4 2xl:px-4 text-xs lg:text-sm font-medium"
    >
      <Clipart />
      <span>{label}</span>
    </button>
  );
};
