import React from "react";
import { classNames } from "../../utils/misc";

export const Input = ({
  label,
  name,
  register,
  errors,
  disabled,
  rules,
  type,
  step,
  hide,
}) => {
  const hasError = (errors && errors[name]) || false;

  return (
    <div
      className={classNames(
        hide ? "hidden" : "",
        "flex flex-col items-center self-stretch"
      )}
    >
      <label className="mb-3 w-full text-sm">{label} :</label>
      <input
        type={type}
        className={classNames(
          hasError ? "border-secondary" : "border-primary-light",
          "block w-full border h-10 lg:h-12 2xl:h-16 px-4 lg:px-10 bg-[#F6F7F9] text-sm"
        )}
        disabled={disabled}
        step={step}
        {...register(name, rules)}
      />
      {errors?.[name] && (
        <div className="w-full text-xs lg:text-base text-red-600">
          {errors?.[name]?.message}
        </div>
      )}
    </div>
  );
};
