const urls = JSON.parse(process.env.NEXT_PUBLIC_RUNTIME_BASE_URL);
const jwt = process.env.NEXT_PUBLIC_API_ACCESS_TOKEN;

export const getUrl = (storeCode) => {
  return urls.find((url) => Object.keys(url)[0] === storeCode)[storeCode];
};

export const getCanonicalAppUrl = (asPath = null, lang) => {
  const canonicalUrls = JSON.parse(process.env.NEXT_PUBLIC_CANONICAL_URL);

  return (
    canonicalUrls.find((url) => Object.keys(url)[0] === lang)[lang] +
    (asPath === "/" ? "" : asPath).split("?")[0]
  );
};

export function getCustomizableProducts(storeCode) {
  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/products" +
      "?searchCriteria[filter_groups][0][filters][0][field]=status" +
      "&searchCriteria[filter_groups][0][filters][0][value]=1" +
      "&searchCriteria[filter_groups][1][filters][1][field]=is_customizable" +
      "&searchCriteria[filter_groups][1][filters][1][value]=1" +
      "&searchCriteria[filter_groups][2][filters][2][field]=is_cuttable" +
      "&searchCriteria[filter_groups][2][filters][2][value]=0" +
      "&searchCriteria[filter_groups][3][filters][3][field]=type_id" +
      "&searchCriteria[filter_groups][3][filters][3][value]=configurable" +
      "&fields=items[sku,name,media_gallery_entries,custom_attributes[short_description]]",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getCustomizableProducts", err);
    });
}

export function getCustomizableProductBySku(storeCode, sku) {
  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/products" +
      "?searchCriteria[filter_groups][0][filters][0][field]=status" +
      "&searchCriteria[filter_groups][0][filters][0][value]=1" +
      "&searchCriteria[filter_groups][1][filters][1][field]=is_customizable" +
      "&searchCriteria[filter_groups][1][filters][1][value]=1" +
      "&searchCriteria[filter_groups][2][filters][2][field]=sku" +
      "&searchCriteria[filter_groups][2][filters][2][value]=" +
      sku +
      "&fields=items[name,sku,options,extension_attributes[configurable_product_links,configurable_product_options]]",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getCustomizableProducts", err);
    });
}

export function getCustomizableProductsLinkByIds(
  storeCode,
  ids,
  withLength = false
) {
  let products = "";
  ids.forEach((id, index) => {
    products += `${id}${index !== ids.length - 1 ? "," : ""}`;
  });

  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/products" +
      "?searchCriteria[filter_groups][0][filters][0][field]=status" +
      "&searchCriteria[filter_groups][0][filters][0][value]=1" +
      "&searchCriteria[filter_groups][1][filters][1][field]=entity_id" +
      "&searchCriteria[filter_groups][1][filters][1][value]=" +
      products +
      "&searchCriteria[filter_groups][1][filters][1][condition_type]=in" +
      `&fields=items[id,name,price,custom_attributes[width${
        withLength ? ",length" : ""
      }]]`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getCustomizableProductsLinkByIds", err);
    });
}

export function getCustomAttributeWidthValue(storeCode) {
  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/products/attributes/width/options",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getCustomAttributeWidthValue", err);
    });
}

export function getCustomAttributeLengthValue(storeCode) {
  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/products/attributes/length/options",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getCustomAttributeLengthValue", err);
    });
}

export function getStoreCustomizationStepJson(storeCode) {
  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/isics/customization-options/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({ path: "customization_steps/general/JSON" }),
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getStoreCustomizationStepJson", err);
    });
}

export function getStoreBorderPrice(storeCode) {
  return fetch(
    getUrl(storeCode) +
      "/rest/" +
      storeCode +
      "/V1/isics/customization-options/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        path: "customization_prices/general/border_price",
      }),
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getStoreBorderPrice", err);
    });
}

export function fetchCartId(storeCode) {
  return fetch(
    getUrl(storeCode) + "/rest/" + storeCode + "/V1/isics/guest-carts",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      credentials: "include", // pass cookie session
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("create cart", err);
    });
}

export function uploadImage(storeCode, cartId, formData) {
  return fetch(
    `${getUrl(storeCode)}/rest/${storeCode}/V1/isics/images/upload/${cartId}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + jwt,
      },
      credentials: "include", // pass cookie session
      body: formData,
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("upload image", err);
    });
}

export function addItemToCart(storeCode, cartId, body) {
  return fetch(
    `${getUrl(storeCode)}/rest/${storeCode}/V1/guest-carts/${cartId}/items`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("failed to add cart", err);
    });
}

export function deleteItemInCart(storeCode, cartId, itemId) {
  return fetch(
    `${getUrl(
      storeCode
    )}/rest/${storeCode}/V1/guest-carts/${cartId}/items/${itemId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("deleteItemInCart", err);
    });
}

export function getItemsInCart(storeCode, cartId) {
  return fetch(
    `${getUrl(storeCode)}/rest/${storeCode}/V1/guest-carts/${cartId}/items`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getItemsInCart", err);
    });
}

export function getAlternativeProduct(storeCode, sku) {
  return fetch(
    `${getUrl(
      storeCode
    )}/rest/${storeCode}/V1/isics/alternative-products/${sku}/products`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + jwt,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("getAlternativeProduct", err);
    });
}

export function postScriptToImageFetch(storeCode, image) {
  let formData = new FormData();
  formData.append("file", image);
  return fetch(
    `${getUrl(storeCode)}/rest/${storeCode}/V1/isics/images/convert`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + jwt,
      },
      body: formData,
    }
  )
    .then((response) =>
      response.status === 200 ? response.blob() : response.status
    )
    .catch((err) => {
      console.log("fail to convert", err);
    });
}
